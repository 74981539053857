<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="门店名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入门店名称"></el-input>
      </el-form-item>
      <el-form-item label="门店类型">
        <el-select v-model="info.store_type" placeholder="请选择">
          <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="门店图片" prop="pic">
        <SelectImgs :selectNum="1" :selectData="pic" :selectFunc="picChoose"></SelectImgs>
      </el-form-item>
      <el-form-item label="省市区" prop="city">
        <el-cascader :options="cityTree" v-model="city" separator="-"></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="info.address" placeholder="请输入详细地址"></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="phone">
        <el-input v-model="info.phone" placeholder="请输入电话"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="ordering">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
export default {
  data() {
    return {
      pic: [],
      cityTree: [],
      city: [],
      typeList: [
        {id: 1,title:'内部门店'},
        {id: 2,title:'外部合作方门店'},
      ],
      info: {
        id: 0,
        pic: '',
        store_type: '',
        title: '',
        phone: '',
        address: '',
        ordering: ''
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入门店名称', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getCityTree()
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  components: {
    SelectImgs
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    picChoose(data) {
      this.pic = [data.pic]
      this.info.pic = data.pic
    },
    getCityTree() {
      this.$api.merchant.cityTree(res => {
        if(res.errcode == 0) {
          this.cityTree = res.data
        }
      })
    },
    getInfo() {
      var that = this
      this.$api.merchant.storeDetail({id: that.info.id}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
          if(that.info.pic) {
            that.pic = [that.info.pic]
          }
          that.city = [that.info.province,that.info.city,that.info.area]
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          param.province = that.city[0]
          param.city = that.city[1]
          param.area = that.city[2]
          if(this.info.id == 0) {
            this.$api.merchant.storeAdd(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            this.$api.merchant.storeEdit(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
</style>
